import React from 'react';
import '/src/gatsby-theme-portfolio-minimal/globalStyles/prism.css';
import { GlobalStateProvider } from '/src/gatsby-theme-portfolio-minimal/context';

export const wrapRootElement = ({ element }) => {
  return <GlobalStateProvider>{element}</GlobalStateProvider>;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) location.state.referrer = prevLocation ? prevLocation.pathname : null;
  if (location && typeof window !== 'undefined') {
    const { hash } = location;
    const selector = hash ? hash.substr(1) : null;
    setTimeout(() => {
      if (window.scrollY !== 0) return;
      const validElement = selector ? document.getElementById(selector) : null;
      if (hash && !!validElement) validElement.scrollIntoView({ behavior: 'smooth' });
    }, 750);
  }
};
